@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Sequel";
    src: url("./assets/fonts/sequel/Sequel100Black45.ttf") format("truetype");
  }
}
